<template>
  <v-container>
    <div class="d-flex justify-end">
      <v-btn color="primary" :to="{ name: 'Subtest.Create' }"
        >New Subtest</v-btn
      >
    </div>
    <v-row class="mb-2">
      <v-col cols="12" md="6">
        <h1>Subtest</h1>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="options.search"
          prepend-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <data-table
      :headers="headers"
      :items="subtests"
      :options.sync="options"
      :server-items-length="totalData"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:[`item.testName`]="{ item: { testName } }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                options.search = testName;
                options.page = 1;
              "
              class="pointer"
              >{{ testName }}</span
            >
          </template>
          <span>Filter this test</span>
        </v-tooltip>
      </template>
      <template
        v-slot:[`item.questionPackName`]="{ item: { questionPackName } }"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              @click="
                options.search = questionPackName;
                options.page = 1;
              "
              class="pointer"
              >{{ questionPackName }}</span
            >
          </template>
          <span>Filter this question pack</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.action`]="{ item: { id } }">
        <div style="width: 100px">
          <v-btn
            color="warning"
            :to="{ name: 'Subtest.Edit', params: { id } }"
            class="mt-1 mb-2"
            medium
            block
            >Edit</v-btn
          >
          <v-btn color="error" class="mb-1" @click="deletePrompt(id)" block
            >Delete</v-btn
          >
        </div>
      </template>
    </data-table>
    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> Delete selected data? </v-card-title>

        <v-card-text>
          This action is irreversible. Deleting this data means other data that
          containing this data will also be deleted.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error darken-1"
            @click="deleteData()"
            :loading="loadingDelete"
            text
          >
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
.pointer:hover {
  cursor: pointer;
}
</style>

<script>
import { mapActions } from "vuex";
import DataTable from "@/components/DataTable.js";
import Subtest from "@/services/subtest";

export default {
  name: "Subtest.Index",
  components: {
    DataTable
  },
  data() {
    return {
      totalData: 0,
      subtests: [],
      loading: true,
      loadingDelete: false,
      options: {
        search: undefined
      },
      headers: [
        { text: "Test", value: "testName" },
        { text: "Question Pack", value: "questionPackName" },
        { text: "Duration", value: "duration" },
        { text: "Point", value: "point" },
        { text: "Position", value: "position" },
        { text: "Action", value: "action", sortable: false }
      ],
      dialog: false,
      selectedId: ""
    };
  },
  watch: {
    options: {
      handler() {
        this.getSubtests();
      },
      deep: true
    }
  },
  created() {
    this.setNavbarTitle("Manage Subtest");
  },
  methods: {
    ...mapActions("drawer", ["setNavbarTitle"]),
    async getSubtests() {
      this.loading = true;
      this.subtests = [];
      const subtests = await Subtest.index(this.options);
      this.subtests = subtests.data.data.subtests;
      this.totalData = subtests.data.data.total_data;
      this.loading = false;
    },
    async deletePrompt(id) {
      this.selectedId = id;
      this.dialog = true;
    },
    async deleteData() {
      try {
        this.loadingDelete = true;
        await Subtest.delete(this.selectedId);
        this.$router.go(this.$router.currentRoute);
      } catch (error) {
        this.loadingDelete = false;
      }
    }
  }
};
</script>
